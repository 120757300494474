import type {AxiosRequestConfig} from 'axios'
import axios from 'axios'
import type {StateAll} from '@/store'
import store from '@/store';
import {ElMessage} from 'element-plus';
import JSONBIG from 'json-bigint';


// 此项必须在 那个前面
axios.defaults.transformResponse = [
    function (data) {
        const json = JSONBIG({
            "storeAsString": true
        })
        const res = json.parse(data)
        return res
    }
]


const instance = axios.create({
    // baseURL: 'http://localhost:3000/',
    // baseURL: 'http://localhost:8002/',
    baseURL: 'http://47.109.28.73:8002/',
    // baseURL: 'http://api.h5ke.top/',
    timeout: 10000
});


instance.interceptors.request.use(function (config) {
    if (config.headers) {
        config.headers.authorization = (store.state as StateAll).users.info.token;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
// 请求接口后统一处理
instance.interceptors.response.use(function (response) {
    //  返回结果 token过期  统一处理数据
    if((response.data.status==0&&response.data.code==40012)||response.data.status==401){
            ElMessage.error('token error');
            store.commit('users/clearToken');
            setTimeout(() => {
                window.location.replace('/login');
            }, 1000)
    }


    // if (response.data.errmsg === 'token error') {

    // } else if (response.data.errmsg === 'error') {
    //     router.push('/500');
    // }
    return response;
}, function (error) {
    return Promise.reject(error);
});

interface Data {
    [index: string]: unknown
}

export interface Result<T = any> {
    code: number;
    msg: string;
    status: string;
    data: T;
}

export interface pager<T = any> {
    total: number;
    dataList: T;
}

export interface PageInfo {
    size :number;
    current :number;
    total:number;
}

export interface File {
    id: number | null;
    relationId: number | null;
    type: number | null;
    name: string ;
    url: string ;
    path:  string | null;
    size: number | undefined ;
    uid: number | null ;
    deleted: number ;
}



interface Http {
    get: <T = any>(url: string, data?: Data | null, config?: AxiosRequestConfig) => Promise<Result<T>>
    post: <T = any>(url: string, data?: Data, config?: AxiosRequestConfig) => Promise<Result<T>>
}

const http: Http = {
    get(url, data, config) {
        return new Promise((resolve, reject) => instance.get(url, {
                params:  data,
                ...config
            }).then((res) => {
                resolve(res.data as any)
            }).catch((error) => {
                reject(error)
            })
        )
    },
    post(url, data, config) {
        return new Promise((resolve, reject) =>  instance.post(url, data, config)
                .then((res) => {
                    resolve(res.data as any)
                })
                .catch((error) => {
                    reject(error)
                }))
    },

}

export default http;
