import {createRouter, createWebHashHistory, createWebHistory, RouteMeta, RouteRecordName, RouteRecordRaw} from 'vue-router'
import _ from 'lodash'
import type {StateAll} from '@/store'
import store from '@/store'
import http from "@/utils/http";

const Home = () => import('@/views/Home/Home.vue');
const Test2 = () => import('@/views/Test/Test2.vue');
const Login = () => import('@/views/Login/Login.vue');
const NotAuth = () => import('@/views/NotAuth/NotAuth.vue');
const NotFound = () => import('@/views/NotFound/NotFound.vue');
const NotServer = () => import('@/views/NotServer/NotServer.vue');


interface RouteRecordRawInfo {
    id: number;
    pid: number;
    path: string;
    name: string;
    component: string;
    menu: boolean;
    title: string;
    icon: string;
    auth: boolean;
}

declare module 'vue-router' {
    interface RouteMeta {
        id?: number;
        pid?: number;
        menu?: boolean
        title?: string
        icon?: string
        auth?: boolean
    }
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        redirect:'/Test2',
        children:[ {
            path: '/Test2',
            name: 'Test2',
            component: Test2
        }  ]
    },{
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/403',
        name: 'notAuth',
        component: NotAuth
    },
    {
        path: '/404',
        name: 'notFound',
        component: NotFound
    },
    {
        path: '/500',
        name: 'notServer',
        component: NotServer
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
];





const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})




// 全局前置钩子
router.beforeEach(async (to, from, next) => {

    const users = (store.state as StateAll).users;
    if (users.router) {
        store.commit('users/setRouter', false);
        //  添加路由后  必须重新加载路由
        if(_.isEmpty(users.info.token)) {
            await router.replace( "/login");
        }else {
            await setRouter();
            await router.replace(to.path=="/404" ?users.path : to.path);
        }
    }else {
        store.commit('users/setPath', to.path);
    }
    next();

});

// 获取菜单 并且处理成树                component: () => require(`@/views${vo.component}`),
const setRouter = async () => {
    const result = await http.get<Array<RouteRecordRawInfo>>('routeRecordRaw/list')
    //  拼装 菜单
    const listRoutes = result.data.map((vo) => {
        const loadView = {
            path: vo.path,
            name: vo.name,
            component: () => import(`@/views${vo.component}`),
            meta: {
                id: vo.id,
                pid: vo.pid,
                menu: vo.menu,
                title: vo.title,
                icon: vo.icon,
                auth: vo.auth
            },
            children: []
        } as RouteRecordRaw;
        return loadView;
    })


    //   把数据处理成树结构
    const mapRoutes = listRoutes.reduce((map: Map<number, Array<RouteRecordRaw>>, obj: RouteRecordRaw) => {
        const meta = obj.meta as RouteMeta;
        if (meta.pid != null) {
            const list = map.get(meta.pid) as Array<RouteRecordRaw>;
            if (list != null) {
                list.push(obj);
            } else {
                map.set(meta.pid, Array.of(obj));
            }
        }
        return map;
    }, new Map<number, Array<RouteRecordRaw>>());
    listRoutes.forEach(obj => {
        const meta = obj.meta as RouteMeta;
        if (meta.id != null) {
            const listChildren = mapRoutes.get(meta.id) as Array<RouteRecordRaw>;
            obj.children = listChildren;
        }
    });
    const routes3 = listRoutes.filter(obj => obj.meta?.pid == 0);

    // 添加路由 菜单中       查看路由 console.log(router.getRoutes());
    routes3.forEach(a=>   router.addRoute(<RouteRecordName>a.name , a));

    //  传递参数 以显示菜单
    router.options.routes = routes3;
}

export default router
