import type {Store} from 'vuex'
import {createStore, useStore as baseUseStore} from 'vuex'
import type {UsersState} from './modules/users'
import users from './modules/users'
import type {SignsState} from './modules/signs'
import signs from './modules/signs'
import type {ChecksState} from './modules/checks'
import checks from './modules/checks'
import type {NewsState} from './modules/news'
import news from './modules/news'
import type {InjectionKey} from 'vue'
import VuexPersistence from 'vuex-persist'

export interface State {
}

export interface StateAll extends State {
    users: UsersState,
    signs: SignsState,
    checks: ChecksState,
    news: NewsState
}
// 持久化 token
const vuexLocal = new VuexPersistence<State>({
    storage: window.localStorage,
    reducer: (state) => ({
        users: {
            path: (state as StateAll).users.path,
            // router:(state as StateAll).users.router,
            info: (state as StateAll).users.info
        }
    }),
})

export const key: InjectionKey<Store<StateAll>> = Symbol()

export function useStore() {
    return baseUseStore(key)
}

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        users,
        signs,
        checks,
        news
    },
    plugins: [vuexLocal.plugin]
})
