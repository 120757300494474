import http from '@/utils/http';
import type { MutationTree, ActionTree, GetterTree } from 'vuex'
import type { State } from '../index'


export interface UsersState {
  router:boolean,
  path: string ,
  info: UserInfo
}
export interface UserInfo {
  token: string ;
  groupName:string | null;
  name: string | null;
  head: string | null;
  id: number | null;
}

const userInfo: UserInfo = <UserInfo>{};

const state: UsersState = {
  router:true,
  path:"",
  info: userInfo
};

const mutations: MutationTree<UsersState> = {

  updateInfos(state, userInf:UserInfo){
    state.info = userInf;
  },
  clearToken(state){
    state.router=true;
    state.info= userInfo;
  },
  setRouter(state,payload){
    state.router=payload;
  },
  setPath(state,path){
    state.path=path;
  },
  setGroupName(state,groupName){
    state.info.groupName=groupName;
  }
};
const actions: ActionTree<UsersState, State> = {
  login(context, payload){
    return http.post('/users/login', payload)
  },
  infos(){
    //校验token 是否合法
    return http.get('/users/infos')
  }
};
const getters: GetterTree<UsersState, State> = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
